/*global prodcat*/
(function(site, $) {
  var formCaptureObj = {}, linkCaptureObj = {};

  Drupal.behaviors.analyticsBehavior = {

    attached: 0,

    findElementIndex: function(arr, value) {
      return _.findIndex(arr, function(elem) {
        return elem === value;
      });
    },

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var positions = [];
      var i;
      var j = productIds.length;
      if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
        for (i = 0; i < j; i++) {
          positions.push(this.findElementIndex(prodcat.data.pids, productIds[i]));
        }
      }
      return positions;
    },

    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attachEvents: function(context) {
      // all code here
      var self = this;
      var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;
      var drupalSkuUpdate = Drupal.settings.analytics ? Drupal.settings.analytics.sku_product_update : false;
      // Flag to check shade selection
      var shadeClickCheck = false;
      // Flag to check change in shades dropdown
      var shadeChangeCheck = false;
      if (self.attached) {
        return;
      }

      // Track Brand Logo
      $('a.header__logo', context).on('click', function(event) {
        var obj;
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

      // Track Product Click
      $(document).on('click', '.js-product-brief a, .js-spp-link', function () {

        $('.js-product-brief-view .js-grid-item-product, .js-product-carousel .js-grid-item, .product-grid__content .js-product-grid-item').each(function(index) {
          $(this).attr('data-index', index);
        });
        var $prodElem = $(this);
        var prodId = $prodElem.closest('.js-product-brief, .js-product').attr('data-product-id');
        var incStr = prodId.includes('PROD');
        if (!incStr) {
          prodId = 'PROD' + prodId;
        }
        var prodPosition = Number($prodElem.closest('.js-product-grid-item, .js-grid-item-product').data('index')) + 1;
        var prodName = $prodElem.closest('.js-product-brief').find('.js-product-display-name-link, .product-brief__name').text().trim();
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name:[prodName]
          });
        }
      });

      // Product click on Recommendation section
      $('.cross_sell_items .recommended-items.cart_cross_sell_item').each(function(index) {
        $(this).attr('data-index', index);
      });
      recomProductSelectors = [
        '.recommended-items a.thumb',
        '.recommended-items .product_name a',
        '.recommended-items .js-quickshop-launch-overlay'
      ];
      $(document).on('click', recomProductSelectors.join(', '), function () {
        var $prodElem = $(this);
        var prodId = $prodElem.closest('.recommended-items').data('product-id');
        if (typeof prodId == 'undefined') {
          var $prodHref = $prodElem.attr('href');
          var splitprod = $prodHref.split('/');
          if (splitprod[3] === 'product') {
            var prodId = 'PROD' + splitprod[5];
          } else {
            var prodId = 'PROD' + splitprod[3];
          }
        }
        var prodPosition = Number($prodElem.closest('.recommended-items').data('index')) + 1;
        var prodName = $prodElem.closest('.recommended-items').find('.product-brief__name a').text().trim();
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name:[prodName]
          });
        }
      });

      // Track Quick Shop
      $(document).on('click', '.js-quickshop-launch-overlay', function() {
        var obj;
        var $targetElement = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodElem = $targetElement.closest('.js-product-brief');
        var prodId = $targetElement.attr('data-product-id');
        var prodName = $targetElement.closest('.js-product').find('.product-brief__name a').text().trim();
        obj = {
          event_label: prodName + ' - ' + [prodId],
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodElem.find('.js-product-price').text().replace(/\s+/g, ' ').trim()]
        };
        var skuId = $(this).closest('.product-brief__detail').find('.js-inv-status-list').attr('data-sku-base-id');
        var skuData = prodcat.data.getSku(skuId);

        if (drupalSkuUpdate && skuData) {
          if (skuData.SHADENAME !== '' && skuData.SHADENAME !== null) {
            obj.product_shade = [skuData.SHADENAME];
          }
          if (skuData.PRODUCT_SIZE !== '' && skuData.PRODUCT_SIZE !== null) {
            obj.product_size = [skuData.PRODUCT_SIZE];
          }
          obj.product_price = [skuData.PRICE.toString()];
          obj.product_product_code = [skuData.PRODUCT_CODE];
          obj.product_sku = [skuId];
        }
        site.track.quickView(obj);
      });

      // Track Predictive Search Product Click and All Results Click
      $('.js-typeahead-gap-tag').on('click', '.result', function() {
        var obj;
        var prods = $(this);
        var term = $('input#search').val();
        var product_name = prods.closest('.js-product').find('.product-brief__name a');
        event.preventDefault();
        self.setLinkCapture(product_name.attr('href'), '_self');
        obj = {
          event_label: term,
          search_keyword: term,
          product_sku: 'SKU' + prods.closest('.js-product').children().attr('data-sku-base-id'),
          product_id: prods.closest('.js-product').attr('data-product-id'),
          product_name: self.stripOutMarkup(product_name.text())
        };
        site.track.evtAction('searchOneResultSelected', obj, self.linkToPage);
      });

      // Track MPP Filters
      $('.js-mpp-sort-menu', context).on('change', function() {
        var obj, elem;
        elem = $('option:selected', this).text();
        obj = {
          event_label: elem
        };
        site.track.evtAction('filterProducts', obj);
      });

      // CHECKOUT EVENTS

      // Track guest user checkout
      $('#checkout_signin_new_user, #checkout_signin_guest_user').on('submit', function() {
        var obj = {};
        site.track.evtAction('checkoutGuestUser', obj);
      });

      // Track return user checkout
      $('#checkout_signin').on('submit', function() {
        var obj = {};
        site.track.evtAction('checkoutReturnUser', obj);
      });

      // Track Payment Method
      var paymentElements = [
        '#checkout_complete input.form-submit',
        '#checkout_billing input.js-submit-payment',
        '#checkout_complete input.js_analytics_checkout_payment',
        '#checkout_review input.place-order',
        '#checkout_billing .js-submit-payment',
        '#checkout_review .button--dark',
        '#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_pp',
        '#js_analytics_checkout_payment',
        '#continue-to-payment-btn'
      ];

      $(document).on('click', paymentElements.join(', '), function() {
        var payment_label = '';
        var payment_type = $('input[name=PAYMENT_TYPE]:checked', context).val() || $('input[name=PAYMENT_OPTION]:checked', context).val() || $('input[name=PAYMENT_METHOD]:checked', context).val();
        var paymentTypePP = [
          'PayPal',
          'PP',
          'PAYMENT_OPTION_PAYPAL'
        ];
        var paymentTypeCC = [
          'Credit Card',
          'CC',
          'PAYMENT_OPTION_MC',
          'PAYMENT_OPTION_VISA',
          'PAYMENT_OPTION_CC',
          'PAYMENT_OPTION_PP',
          'PAYMENT_OPTION_CARDLINK'
        ];
        var paymentTypeCOD = [
          'PAYMENT_OPTION_COD',
          'COD'
        ];
        var paymentTypeMyBank = [
          'PAYMENT_OPTION_MYBANK'
        ];
        if ($.inArray(payment_type, paymentTypePP) > -1) {
          payment_label = 'paypal';
        } else if ($.inArray(payment_type, paymentTypeCC) > -1) {
          payment_label = 'creditcard';
        } else if ($.inArray(payment_type, paymentTypeCOD) > -1) {
          payment_label = 'cashondelivery';
        } else if ($.inArray(payment_type, paymentTypeMyBank) > -1) {
          payment_label = 'MyBank';
        }
        var obj = {
          event_label: payment_label
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // Track Paypal Express Check-out
      $('a.paypal-checkout').on('click', function() {
        var obj = {
          event_label: 'paypal'
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });
      // END CHECKOUT EVENTS

      //Egift SPP 
      $(document).on('click', '.js-egift-cta-button', function() {
        var productId = '';
        var productName = '';
        var $prodElem = '';
        var obj;
        var clickText = '';
        var $prod = '';
        $prod = $(this);
        $prodElem = $prod.closest('.js-product');
        productId = $prodElem.attr('data-product-id');
        productName = $prodElem.attr('data-product-name');
        clickText = $prod.text();
        obj = {
          'event_category': 'Ecommerce',
          'event_action': clickText,
          'event_label': productName + ' - ' + productId
        };
        site.track.evtLink(obj);
      });

      // Alt image tracking start
      if ($('body').hasClass('section-product') && drupalAltImgSettings) {
        // Disable alt image tagging while selecting shades
        $(document).on('click', '.js-product-shade', function() {
          shadeClickCheck = true;
        });
        // Disable alt image tagging while changing shades
        $(document).on('change', '.js-sku-menu', function() {
          shadeChangeCheck = true;
        });
        // Trigger Alt image event only when alternate images are available
        if ($('body').hasClass('device-pc') && $('.js-spp-carousel__thumbnail').length > 1) {
          trackAltImageTrackingClickPC();
        } else if ($('body').hasClass('device-mobile')) {
          trackAltImageTrackingMobile();
        }
      }

      // Track Alt image click - desktop
      function trackAltImageTrackingClickPC() {
        $(document).on('click', '.js-spp-carousel__thumbnail', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('img').attr('src');
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
        });
      }

      // Track Alt image swipe - Mobile
      function trackAltImageTrackingMobile() {
        $(function() {
          $(document).on('afterChange', '.js-spp-carousel', function() {
            if (!shadeClickCheck && !shadeChangeCheck) {
              var $targetElem = $(this);
              var altImageName = '';
              var prodDetails = buildAltImageProductName($targetElem);
              var bgImageUrl = $targetElem.siblings('.js-spp-carousel-thumbs').find('.slick-active img').attr('src');
              if (bgImageUrl) {
                altImageName = buildAltImageFileName(bgImageUrl);
              }
              trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'swipe');
            }
            shadeClickCheck = false;
            shadeChangeCheck = false;
          });
        });
      }

      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.js-product');
        var prodId = prodElem.attr('data-product-id');
        var prodName = prodElem.find('.product-full__name').text();
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end

      // Smashbox Foundation finder start.
      if ($('body').hasClass('section-foundation-finder')) {
        trackSmasBFinderTagging();
      }

      function trackSmasBFinderTagging() {
        initSmasBFinderTagging();

        function foundationstartTagging() {
          $(document).on('click', '.ff-quiz__button--start', function() {
            var startLable = $(this).text().trim();
            var stepCount = 1;
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', startLable, 'click');
            trackNextPage('/foundation-finder/step' + stepCount, '/foundation-finder/step' + stepCount, 'foundation-finder - Step ' + stepCount);
          });
        }

        function foundationNextTagging() {
          $(document).on('Next', function(event, data) {
            var quizAnswer = $('.ff-quiz__footer-container').closest('.slick-active').find('.ff-quiz__answer.active .ff-quiz__headline--secondary').text().trim();
            var quizNav = $('.ff-quiz__nav-item.active').find('.ff-quiz__nav-text').text().trim();
            var stepIndex = $('.ff-quiz__footer-container').closest('.slick-active').data('slick-index');
            var shadeAnswer = $('.ff-quiz__footer-container').closest('.slick-active').find('.ff-quiz__answer.active').data('answer');
            var shadeUrl = $('.ff-quiz__footer-container').closest('.slick-active').find('.ff-quiz__answer.active img.ff-quiz__shade-image').attr('src');
            var shadequizAnswer = quizNav + ' - ' + shadeAnswer + ' - ' + shadeUrl;
            var quizLabel = quizNav + ' - ' + quizAnswer;
            var slickStep = data.slideIndex + 1;
            var eventLabel;
            if (data.slideIndex === '4') {
              eventLabel = shadequizAnswer;
              trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'questions', eventLabel);
            } else {
              eventLabel = quizLabel;
              trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'questions', eventLabel);
              trackNextPage('/foundation-finder/step' + slickStep, '/foundation-finder/step' + slickStep, 'foundation-finder - Step ' + slickStep);
            }
          });
        }

        function trackUndertoneRequiz() {
          $(document).on('click', '.ff-quiz__slide-header a, .ff-quiz__start-over-link', function() {
            var eventLable = $(this).text().trim();
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', eventLable, 'Click');
          });
        }

        function resultPageTagging() {
          $(document).on('filterResult', function() {
            var $menuItems = $('.ff-results__recommended-products');
            var $recommendedItems = $('.js-ff-results-match__recommended-products');
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;
            $menuItems.find('.js-product').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.attr('data-product-id'));
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/results');
              $(this).attr('data-index', index + 1);
            });

            $recommendedItems.find('.js-product').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.attr('data-product-id'));
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/also_recommended');
              $(this).attr('data-index', index + 1);
            });

            var objView = {
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: location.origin + location.pathname + '/results'
            };
            site.track.evtView(objView);
          });
        }

        function trackNextPage(pageurl, plocation, pname) {
          var objView = {};
          Object.assign(objView, {
            page_url: pageurl,
            location: location.origin + plocation,
            page_name: pname
          });
          site.track.evtView(objView);
          objView = {};
        }

        function initSmasBFinderTagging() {
          foundationstartTagging();
          foundationNextTagging();
          trackUndertoneRequiz();
          resultPageTagging();
        }
      }
      // Smashbox Foundation finder end.

      // Primer Finder Tagging start.
      if ($('body').hasClass('section-primer-finder') || $('body').hasClass('section-bazini-bul')) {
        trackPrimerFinderTagging();
      }

      function trackPrimerFinderTagging() {
        $('.primer-finder-quiz__nav-menu .primer-finder-quiz__nav-item').each(function(key) {
          $(this).attr('data-step-index', key);
        });

        initPrimerFinderTagging();

        function primerstartTagging() {
          $(document).on('click', '.primer-finder-quiz__button--start', function() {
            var stepCount = 1;
            trackEvent('diagtools', 'diagnostic tool - Primer Finder', 'start button', 'click');
            trackNextPage('/primer-finder/step' + stepCount, '/primer-finder/step' + stepCount, 'primer-finder - Step ' + stepCount);
          });
        }

        function primerNextTagging() {
          $(document).on('Next', function(event, data) {
            var quizAnswer = $('.primer-finder-quiz__footer-container').closest('.slick-active').find('.primer-finder-quiz__answer.active .primer-finder-quiz__headline--secondary').text().trim();
            var quizNav = $('.primer-finder-quiz__nav-item.active').find('.primer-finder-quiz__nav-text').text().trim();
            var eventLabel = quizNav + ' - ' + quizAnswer;
            var slickStep = data.slideIndex + 1;
            if (data.slideIndex === 3) {
              trackEvent('diagtools', 'diagnostic tool - Primer Finder', 'questions', eventLabel);
            } else {
              trackEvent('diagtools', 'diagnostic tool - Primer Finder', 'questions', eventLabel);
              trackNextPage('/primer-finder/step' + slickStep, '/primer-finder/step' + slickStep, 'primer-finder - Step ' + slickStep);
            }
          });
        }

        function trackNavTagging() {
          $(document).on('click', '.primer-finder-quiz__nav-menu .primer-finder-quiz__nav-item', function() {
            var stepCount = $(this).data('step-index') + 1;
            trackNextPage('/primer-finder/step' + stepCount, '/primer-finder/step' + stepCount, 'primer-finder - Step ' + stepCount);
          });
        }

        function trackUndertoneRequiz() {
          $(document).on('click', '.primer-finder-quiz__start-over-link', function() {
            trackEvent('diagtools', 'diagnostic tool - Primer Finder', 'start over', 'Click');
          });
        }

        function resultPageTagging() {
          $(document).on('filterResult', function() {
            var $menuItems = $('.primer-finder-results__recommended-products');
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;
            $menuItems.find('.finder-result').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.find('.js-product-brief').attr('data-product-id'));
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/results');
              $(this).attr('data-index', index + 1);
            });

            var objView = {
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: location.origin + location.pathname + '/results',
              page_url: '/primer-finder/results',
              page_name: 'primer-finder - results'
            };
            site.track.evtView(objView);
          });
        }

        function trackNextPage(pageurl, plocation, pname) {
          var objView = {};
          Object.assign(objView, {
            page_url: pageurl,
            location: location.origin + plocation,
            page_name: pname
          });
          site.track.evtView(objView);
          objView = {};
        }

        function initPrimerFinderTagging() {
          primerstartTagging();
          primerNextTagging();
          trackNavTagging();
          trackUndertoneRequiz();
          resultPageTagging();
        }
      }
      // Primer Finder Tagging end.

      // trackEvent common function call start.
      function trackEvent(eName, eCategory, eAction, elabel, eventObj) {
        eventObj = eventObj || {};
        Object.assign(eventObj, {
          event_name: eName,
          event_category: eCategory,
          event_action: eAction,
          event_label: elabel
        });
        site.track.evtLink(eventObj);
      }
      // trackEvent common function call end.

      self.attached = 1;
    }
  };
  site.elcEvents.addListener('tealium:loaded', function() {
    Drupal.behaviors.analyticsBehavior.attachEvents(document);
  });
}(window.site || {}, jQuery));
